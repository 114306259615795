import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DesktopCurrencyConverter: () =>
      import("./desktop/DesktopCurrencyConverter/DesktopCurrencyConverter.vue"),
    MobileCurrencyConverter: () =>
      import("./mobile/MobileCurrencyConverter/MobileCurrencyConverter.vue"),
  },
})
export default class CurrencyConverter extends Vue {}
