import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    DesktopReferralProgram: () =>
      import("./desktop/DesktopReferralProgram/DesktopReferralProgram.vue"),
    MobileReferralProgram: () =>
      import("./mobile/MobileReferralProgram/MobileReferralProgram.vue"),
  },
})
export default class ReferralProgram extends Vue {}
